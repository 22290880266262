const BASE = "/api";

function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}
const getDateStrFromTs = (d) => {
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

// const fixDateStr = (dateStr) => {
//   if (!dateStr) return dateStr;
//   const [year, month, day] = dateStr.split("-");
//   const d = new Date();
//   d.setFullYear(Number(year));
//   d.setMonth(Number(month - 1));
//   d.setDate(Number(day));
//   console.log(convertTZ(d, "US/Pacific"));
//   return getDateStrFromTs(convertTZ(d, "US/Pacific"));
// };

const fixDateStr = (dateStr) => {
  return dateStr;
  if (!dateStr) return dateStr;
  if (dateStr != getDateStrFromTs(new Date())) return dateStr;

  const nowD = new Date();
  const ptD = convertTZ(new Date(), "US/Pacific");
  if (nowD.getDay() != ptD.getDay()) nowD.setDate(nowD.getDate() + 1);

  return getDateStrFromTs(nowD);
};

class API {
  static async getAnalyticsPagesData(startDate, endDate, articleId = "") {
    // await response of fetch call

    let response = await fetch(
      `${BASE}/analytics/ga/pages?start_date=${fixDateStr(startDate)}&end_date=${fixDateStr(
        endDate
      )}&limit=1000&article_id=${articleId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "auth_key " + localStorage.getItem("apikey"),
        },
        // cache: "force-cache",
      }
    );
    let data = await response.json();
    return data;
  }

  static async getAnalyticsRealtimeData(articleId = "") {
    // await response of fetch call
    let response = await fetch(
      `${BASE}/analytics/realtime/activeusers?&mode=page2&article_id=${encodeURI(articleId)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "auth_key " + localStorage.getItem("apikey"),
        },
        // cache: "force-cache",
      }
    );
    let data = await response.json();
    return data;
  }
  static async getAnalyticsRealtimePagesData() {
    // await response of fetch call
    let response = await fetch(`${BASE}/analytics/realtime/pageviews?&mode=page2`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "auth_key " + localStorage.getItem("apikey"),
      },
      // cache: "force-cache",
    });
    let data = await response.json();
    return data;
  }

  static async getDiscoverdData(startDate, endDate, articleIds = "") {
    // await response of fetch call

    let response = await fetch(
      `${BASE}/search-console/discover?start_date=${fixDateStr(startDate)}&end_date=${fixDateStr(
        endDate
      )}&limit=100&article_ids=${articleIds}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "auth_key " + localStorage.getItem("apikey"),
        },
        // cache: "force-cache",
      }
    );
    let data = await response.json();
    return data;
  }

  static async getPinnedArticles() {
    let response = await fetch(`${BASE}/articles/pinned`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "auth_key " + localStorage.getItem("apikey"),
      },
      // cache: "force-cache",
    });
    let data = await response.json();
    return data;
  }

  static async getArticleData(articleId) {
    if (!articleId) return { title: undefined, author: undefined, pub_date: undefined };
    function parseHtmlEnteties(str) {
      return str.replace(/&#([0-9]{1,3});/gi, function (match, numStr) {
        var num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
      });
    }
    let response = await fetch(`${BASE}/articles/info?v=1&id=${articleId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "auth_key " + localStorage.getItem("apikey"),
      },
      cache: "default",
    });
    let data = await response.json();
    if (data.title) data.articleId = articleId;
    if (data.author) data.author = parseHtmlEnteties(data.author);
    return data;
  }
}

export default API;
