import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { visuallyHidden } from "@mui/utils";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import { isKore } from "../../utils";
function descendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function acendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => acendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    label: "מס",
  },
  {
    id: "title",
    numeric: false,
    label: "כותרת",
  },
  {
    id: "url",
    numeric: false,
    label: "קישור",
  },
  {
    id: "author",
    numeric: false,
    label: "שם הכתב",
  },
  {
    id: "pubdate",
    numeric: false,
    label: "תאריך פרסום",
  },
  {
    id: "views",
    numeric: true,
    label: "צפיות",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    //console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"right"}
            //padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align={"left"}
            >
              <h4> {headCell.label}</h4>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getDateStrFromTs = (d) => {
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

const getDateInThreeM = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 3);
  return d;
};

const getDateInStartM = () => {
  const d = new Date();
  if (d.getDate() == 1) d.setDate(0);
  else d.setDate(1);
  return d;
};

const EnhancedTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log(searchParams.entries());

  const updateData = async (startDate, endDate, articleIds) => {
    setIsLoading(true);
    try {
      const data = await API.getAnalyticsPagesData(startDate, endDate, articleIds);
      const rows = data.rows ?? [];
      setRows(
        rows.map((d, idx) => ({
          link: !isKore ? "https://" + d[0] : "https://www.kore.co.il" + d[0],
          views: Number(d[1]),
          id: idx + 1,
        }))
      );
    } catch (error) {}
    setIsLoading(false);
  };
  const dateFromFilterStr = (s) => {
    const d = new Date();
    if (s == "monthstart") {
      if (d.getDate() == 1) d.setDate(0);
      else d.setDate(1);
    }
    if (s == "yesterday") {
      d.setDate(d.getDate() - 1);
    }
    if (s == "lastweek") {
      d.setDate(d.getDate() - 7);
    }
    if (s == "lastmonth") {
      d.setMonth(d.getMonth() - 1);
    }
    if (s == "lastyear") {
      d.setFullYear(d.getFullYear() - 1);
    }
    return d;
  };

  React.useEffect(async () => {
    setDateFilterStr(searchParams.get("date_str") || "monthstart");
    if (!searchParams.get("start_date") && !searchParams.get("end_date")) {
      setStartDate(getDateStrFromTs(dateFromFilterStr(searchParams.get("date_str"))));
    }

    const _startDate =
      searchParams.get("start_date") || getDateStrFromTs(dateFromFilterStr(dateFilterStr));
    setStartDate(_startDate);
    const _endDate = searchParams.get("end_date") || getDateStrFromTs(new Date());
    setEndDate(_endDate);
    const _articleIds = searchParams.get("articles") || "";
    setArticleIds(_articleIds);

    // if (!searchParams.get("date_str")) {
    //   setDateParamSet("monthstart");
    // }
    setDateFilterStr(searchParams.get("date_str") || "monthstart");
    // if (searchParams.get("date_str") && !dateParamSet) {
    //   setDateFilterStr(searchParams.get("date_str"));
    //   setDateParamSet(true);
    // }

    updateData(_startDate, _endDate, _articleIds);
  }, [searchParams]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("views");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dateParamSet, setDateParamSet] = React.useState(false);

  const [startDate, setStartDate] = React.useState(
    searchParams.get("start_date") || getDateStrFromTs(getDateInStartM())
  );
  const [endDate, setEndDate] = React.useState(
    searchParams.get("end_date") || getDateStrFromTs(new Date())
  );
  const [articleIds, setArticleIds] = React.useState(searchParams.get("articles") || "");
  const [dateFilterStr, setDateFilterStr] = React.useState("monthstart");
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const updateArticleDataForRow = async (articleLink) => {
    setRows(
      [...rows].map((r) => {
        if (r.link != articleLink) return r;

        r.isLoading = true;
        return r;
      })
    );

    const { title, author, pub_date } = await API.getArticleData(
      articleLink.split(isKore ? "viewArticle/" : "news/")[1].replace("/", "")
    );

    setRows(
      [...rows].map((r) => {
        if (r.link != articleLink) return r;
        r.title = title || "שגיאה בטעינה";
        r.author = author || "שגיאה בטעינה";
        r.pubdate = pub_date || "שגיאה בטעינה";
        return r;
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const isMobile = window.innerWidth < 670;
  return (
    <Box sx={{ width: "95%", "max-width": "2000px", margin: "auto", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <img
          height={"50px"}
          width={"auto"}
          src="https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg"
        ></img>
        <h1 style={{ fontWeight: "bold", marginRight: "15px", marginLeft: "15px" }}>
          נתוני הכתבות באנליטיקס
        </h1>
        <img
          height={"50px"}
          width={"auto"}
          style={{ borderRadius: "9px" }}
          src={isKore ? "https://i.imgur.com/PTmf6qd.png" : "https://i.imgur.com/AvvXZkl.png"}
        ></img>
      </div>
      <center>{/* <h1>גוגל דיסקבר</h1> */}</center>
      <Paper sx={{ mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: isMobile ? "10px" : "",
            }}
          >
            <div style={{}}>
              <TextField
                id="startdate"
                label="התחלה"
                type="date"
                sx={{ width: 170 }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setDateFilterStr("-");
                }}
                value={startDate}
              />
              <TextField
                id="enddate"
                label="סיום"
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setDateFilterStr("-");
                }}
                value={endDate}
                sx={{
                  width: 170,
                  marginRight: !isMobile ? "5px" : "",
                  marginLeft: !isMobile ? "5px" : "",

                  margin: isMobile ? "10px 0 5px" : "",
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl>
                <InputLabel>סינון</InputLabel>
                <Select
                  size="small"
                  value={dateFilterStr}
                  label="סינון"
                  onChange={(e) => {
                    setDateFilterStr(e.target.value);
                    setStartDate(getDateStrFromTs(dateFromFilterStr(e.target.value)));
                  }}
                >
                  <MenuItem value={"monthstart"}>מתחילת החודש</MenuItem>
                  <MenuItem value={"yesterday"}>אתמול</MenuItem>
                  <MenuItem value={"lastweek"}>שבוע אחרון</MenuItem>
                  <MenuItem value={"lastmonth"}>חודש אחרון</MenuItem>
                  <MenuItem value={"lastyear"}>שנה אחרונה</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="מזהי כתבות"
                variant="outlined"
                size="small"
                onChange={(e) => setArticleIds(e.target.value)}
                value={articleIds}
                sx={{ width: isMobile ? "100%" : "unset" }}
              />
              <Button
                variant="contained"
                color="success"
                sx={{
                  marginRight: !isMobile ? "5px" : "",
                  marginTop: isMobile ? "10px" : "",
                  width: isMobile ? "100%" : "",
                }}
                onClick={() => {
                  setStartDate(
                    startDate != getDateStrFromTs(getDateInStartM())
                      ? startDate
                      : getDateStrFromTs(getDateInThreeM())
                  );

                  setSearchParams({
                    start_date: startDate,
                    end_date: endDate,
                    articles: articleIds,
                    date_str: dateFilterStr,
                  });
                  //updateData();
                }}
                disabled={isLoading}
              >
                חיפוש
              </Button>
            </div>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {[...rows]
                // .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  if (!row.isLoading) updateArticleDataForRow(row.link);
                  return (
                    <TableRow hover key={row.link}>
                      <TableCell
                        align="right"
                        style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => {
                          const _startDate =
                            startDate != getDateStrFromTs(getDateInStartM())
                              ? startDate
                              : getDateStrFromTs(getDateInThreeM());
                          const _endDate = getDateStrFromTs(new Date());
                          const articleId = row.link
                            .split(isKore ? "viewArticle/" : "news/")[1]
                            .replace("/", "");
                          setStartDate(_startDate);
                          setEndDate(_endDate);
                          setArticleIds(articleId);
                          setDateFilterStr("-");

                          setSearchParams({
                            start_date: _startDate,
                            end_date: _endDate,
                            articles: articleId,
                            date_str: "lastyear",
                          });
                          //updateData();
                        }}
                      >
                        {row.id}
                      </TableCell>

                      <TableCell align="right">{row.title || "טוען.."}</TableCell>
                      <TableCell align="right">
                        <a target="_blank" href={row.link}>
                          {row.link.split(".co.il")[1]}
                        </a>
                      </TableCell>
                      <TableCell align="right">{row.author || "טוען.."}</TableCell>
                      <TableCell align="right">{row.pubdate || "טוען.."}</TableCell>
                      <TableCell align="right">{row.views.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
export default EnhancedTable;
