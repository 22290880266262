import React from "react";
import "./styles/App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Discover from "./components/search-console/Discover";
import Pages from "./components/analytics/Pages";
import RealTime from "./components/analytics/RealTime";
import RealTimePinned from "./components/analytics/RealTimePinned";
import RealTimePages from "./components/analytics/RealTimePages";
import { isKore } from "./utils";
import { useLocation } from "react-router";
// import Main from "./components/main";

const checkAuth = () => {
  const token = localStorage.getItem("apikey");
  if (!token) {
    return false;
  }

  //   try {
  //     const { exp } = decode(token);
  //     if (exp < new Date().getTime() / 1000) {
  //       return false;
  //     }
  //   } catch (e) {
  //     return false;
  //   }

  return true;
};

const AuthRote = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? <Component {...props} /> : <Navigate to={{ pathname: "login" }} />
    }
  />
);

function RequireAuth({ children, path }) {
  let location = useLocation();

  if (!checkAuth()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={"/login?redirect=" + encodeURI(path)} state={{ from: location }} replace />
    );
  }

  return children;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <h1>{"f"}</h1> */}
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/search-console/discover"
            element={
              <RequireAuth>
                <Discover />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/analytics/pages"
            element={
              <RequireAuth path="/analytics/pages">
                <Pages />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/analytics/realtime/"
            element={
              <RequireAuth path="/analytics/realtime/">
                <RealTime />
                <RealTimePinned extended={true} />

                <RealTimePages />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/analytics/realtime/activeusers"
            element={
              <RequireAuth path="/analytics/realtime/activeusers">
                <RealTime />
                <RealTimePages />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/analytics/realtime/pinned-articles"
            element={
              <RequireAuth path="/analytics/realtime/pinned-articles">
                <RealTimePinned />
              </RequireAuth>
            }
          />
          {/* <AuthRote exact path="/:openPlatform?" component={Main} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
